<template>
  <div>
    <h1><fa-icon :icon="['fas', 'heart']" class="red--text text--accent-3 mr-2" />{{ $t('views.supporter.h1_title') }}</h1>
    <p>{{ $t('views.supporter.p_text_1') }}</p>
    <p>
      <i18n path="views.supporter.p_text_2">
        <template #heartIcon><fa-icon :icon="['fas', 'heart']" class="red--text text--accent-3" /></template>
        <template #mugIcon>
          <fa-icon :icon="['fad', 'coffee-togo']" class="brown--text text--lighten-2" />
        </template>
        <template #withoutSubscription>{{ $t('views.supporter.without_subscription') }}</template>
        <template #title>{{ $t('globals.title') }}</template>
      </i18n>
    </p>
    <p>
      <i18n path="views.supporter.p_text_3">
        <template #adFree
          ><strong>{{ $t('views.supporter.ad_free') }}</strong></template
        >
        <template #title>{{ $t('globals.title') }}</template>
      </i18n>
    </p>
    <v-row>
      <v-col class="mt-3" offset="2" offset-sm="4" offset-md="3" offset-lg="4" cols="8" sm="4" md="6" lg="4">
        <v-select
          v-model="amount"
          :disabled="disableFormElements"
          color="blue-grey"
          :items="items"
          label="Deine Unterstüzung"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <black-friday-ad size="large"></black-friday-ad>
    <p>{{ $t('views.supporter.p_text_4') }}</p>
    <p>{{ $t('views.supporter.p_text_5') }}</p>
    <v-row align="center">
      <v-col class="mt-9" offset="1" offset-sm="3" offset-md="2" offset-lg="3" cols="10" sm="6" md="8" lg="6">
        <v-slider
          v-model="period"
          :disabled="disableFormElements"
          color="blue-grey"
          :label="$t('views.supporter.duration')"
          thumb-label="always"
          :min="0"
          :max="maxPeriod"
          :step="1"
          class="mr-3 mb-n6"
        ></v-slider>
        <p v-if="!disableFormElements" class="text-center text-caption">
          <i18n path="views.supporter.caption_until">
            <template #actualPeriod>{{ actualPeriod === null ? `keine` : formatDate(actualPeriod) }}</template>
          </i18n>
          -
          <i18n path="views.supporter.caption_new">
            <template #newPeriod>{{ formatDate(futurePeriod) }}</template>
          </i18n>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-6 text-center">
        <Payment
          v-if="isAuthenticated"
          :amount="amount"
          :period="period"
          @paymentProcessing="paymentProcessing"
          @paymentApproved="hideInfoAlert"
        />
        <v-alert v-else dense text color="info">
          <template #prepend>
            <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
          </template>
          <p class="mb-0 text-left">
            {{ $t('views.supporter.alert_not_authenticated') }}
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-alert v-if="isAuthenticated" v-show="showInfoAlert" dense text color="info">
      <template #prepend>
        <fa-icon :icon="['fal', 'circle-info']" size="xl" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('views.supporter.alert_info') }}</p>
    </v-alert>
  </div>
</template>

<script>
import Payment from '@/components/Payment'
import BlackFridayAd from '@/components/black-friday/Ad'
import { settings } from '@/config'
import { mapGetters } from 'vuex'
import { blackFriday } from '@/mixins'

export default {
  name: 'Supporter',
  components: {
    Payment,
    BlackFridayAd,
  },
  mixins: [blackFriday],
  data() {
    return {
      items: [
        { text: '10,00 €', value: 10 },
        { text: '20,00 €', value: 20 },
        { text: '30,00 €', value: 30 },
        { text: '40,00 €', value: 40 },
        { text: '50,00 €', value: 50 },
      ],
      period: this.isBlackFriday() ? 15 : 12,
      amount: 10,
      disableFormElements: false,
      showInfoAlert: true,
    }
  },
  computed: {
    ...mapGetters(['profile', 'user', 'actualPeriod']),
    futurePeriod() {
      let actualPeriod = this.actualPeriod === null ? new Date() : new Date(this.actualPeriod)
      return new Date(actualPeriod.setMonth(actualPeriod.getMonth() + this.period))
    },
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    maxPeriod() {
      return this.amount * (this.isBlackFriday() ? 1.5 : 1.2)
    },
  },
  watch: {
    amount: function () {
      this.period = this.maxPeriod
    },
  },
  methods: {
    paymentProcessing(val) {
      this.disableFormElements = val
    },
    hideInfoAlert(val) {
      this.showInfoAlert = !val
    },
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val.toLocaleDateString(this.$i18n.locale, options)
    },
  },
}
</script>

<style lang="scss">
.black-friday-logo {
  font-size: 2.5rem;
  @media (min-width: 413px) {
    font-size: 3rem;
  }
}
</style>
