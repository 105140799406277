<template>
  <div>
    <v-alert v-if="paymentError" dense text color="error" class="text-left">
      <template #prepend>
        <fa-icon :icon="['fal', 'times']" size="2x" class="ma-2 mr-4" />
      </template>
      <p class="mb-0">{{ $t('components.payment.p_error') }}</p>
    </v-alert>
    <div v-if="paymentApproved">
      <p><fa-icon class="green--text mr-2" :icon="['fas', 'check']"></fa-icon>{{ $t('components.payment.p_approved') }}</p>
      <p>
        <i18n path="components.payment.p_confirmation">
          <template #title>{{ $t('globals.title') }}</template>
          <template #actualPeriod>{{ formatDate(actualPeriod) }}</template>
        </i18n>
      </p>
    </div>
    <btn-home v-if="paymentApproved || paymentError"></btn-home>
    <div v-else v-show="!paymentProcessing && !paymentError" class="mx-auto" style="max-width: 250px !important">
      <div ref="paypal"></div>
    </div>
    <p v-if="paymentProcessing && !paymentApproved">{{ $t('components.payment.p_processing') }}</p>
  </div>
</template>

<script>
import { Com } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { payment, settings } from '@/config'
import BtnHome from '@/components/btn/Home'

export default {
  name: 'Payment',
  components: { BtnHome },
  props: {
    amount: {
      type: Number,
      default: 10,
    },
    period: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      paymentProcessing: false,
      paymentApproved: false,
      paymentError: false,
    }
  },
  computed: {
    ...mapGetters(['profile', 'actualPeriod']),
    clientId() {
      return payment.paypal.clientId
    },
    currency() {
      return payment.currency
    },
    payment() {
      return payment
    },
    tax() {
      return payment.tax
    },
  },
  watch: {
    paymentProcessing(val) {
      this.$emit('paymentProcessing', val)
    },
    paymentApproved(val) {
      this.$emit('paymentApproved', val)
    },
  },
  mounted() {
    let paypalScript = document.createElement('script')
    paypalScript.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${this.clientId}&currency=${this.currency}&commit=true`)
    paypalScript.addEventListener('load', this.setLoaded)
    document.head.appendChild(paypalScript)
  },
  methods: {
    ...mapActions(['hidePayment', 'setProfile']),
    setLoaded: function () {
      window.paypal['Buttons']({
        style: {
          color: 'gold',
          height: 40,
          label: 'pay',
          layout: 'horizontal',
          shape: 'pill',
          tagline: false,
        },

        createOrder: async function (data, actions) {
          const netAmount = Math.round((this.amount / (100 + this.tax)) * 10000) / 100
          const taxAmount = Math.round((this.amount / (100 + this.tax)) * 1900) / 100
          const order = await actions.order.create({
            intent: 'CAPTURE',
            purchase_units: [
              {
                amount: {
                  currency_code: this.currency,
                  value: this.amount,
                  breakdown: {
                    item_total: {
                      currency_code: this.currency,
                      value: netAmount,
                    },
                    tax_total: {
                      currency_code: this.currency,
                      value: taxAmount,
                    },
                  },
                },
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
          })
          this.paymentProcessing = true
          return order
        }.bind(this),
        onCancel: () => {
          this.paymentProcessing = false
        },
        onError: () => {
          this.paymentProcessing = false
          this.paymentError = true
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(async (details) => {
            this.paymentApproved = true
            const com = new Com()
            await com.createApprovedPayment(this.amount, this.period, details['payer']['email_address'], details['id'])
            const profile = await com.getProfile()
            this.setProfile(profile.data)
          })
        }.bind(this),
      }).render(this.$refs.paypal)
    },
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val === null ? '' : val.toLocaleDateString(this.$i18n.locale, options)
    },
  },
}
</script>

<style lang="scss" scoped></style>
